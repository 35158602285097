<template>
  <div>
    <BaseModal
      dataClass="divide-section-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
      @show-modal="clear(obj)"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseSearchCondition
            :searchCondition="searchCondition"
            :searchConditionInfo="searchConditionInfo"
            @clear="clear(obj)"
            @search="search(obj)"
          >
          </BaseSearchCondition>
          <br />
          <!-- テーブル -->
          <BasePagingTable
            v-model="searchCondition.size"
            id="work-list-table"
            select-mode="range"
            :fields="fields"
            :items.sync="tableItems"
            :selected-items.sync="selectedItem"
            :columnInfoPage="pagingInfo.page"
            :columnInfoSize="pagingInfo.size"
            :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
            :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
            :perPage="perPage"
            @size-changed="updateSize"
          />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 選択ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.select"
          :disabled="!multiSelected"
          @click="select"
        />
        <!-- 選択解除ボタン -->
        <BaseButton
          v-if="useUnset"
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.unselect"
          @click="unselect"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 場所マスタモーダル -->
    <PlaceModal
      :id="makeSubModalId"
      type="entry"
      :displayModals="displayModals"
      :useFullPlaceKind="true"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
  openSubModal,
  changePlaceKind,
} from '@/common/Common.js'
import { search, clear } from '@/master/sectionDivision/SectionDivisionModal.js'
import { DELETED_KIND } from '@/common/const.js'

export default {
  name: 'sectionDivisionModal',

  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
  },

  data() {
    return {
      placeModalKbn: 0,
      cityModalKbn: 0,
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
    }
  },

  props: {
    selectedSearchCondition: {
      type: Object,
      default: () => {},
    },
    displayModals: {
      type: String,
      // 'one'  ->'section-division-modal'
      // 'two'  ->'section-division-modal-edit'
      // 'three'->'section-division-modal-three'
      // 'four' ->'section-division-modal-four'
      default: 'one',
    },
    useUnset: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    selectedSearchCondition() {
      this.searchCondition.departurePlaceId = this.selectedSearchCondition.departurePlaceId
      this.searchCondition.arrivalPlaceId = this.selectedSearchCondition.arrivalPlaceId
    },
  },

  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.SECTION_DIVISION_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 搬出場所区分
            type: 'select',
            id: 'departurePlaceType',
            columnInfo: searchConditionInfo.departure_place_type,
            options: getListOptions(this.MASTER_CODE.PLACE_LEVEL),
            onChangeCallback: () => {},
          },
          {
            // 搬出場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.placeModalKbn = 1
              //this.openSubModal(this, 'place-modal')
              this.openSubModal(this, this.makeSubModalId)
            },
          },
          {
            // 搬入場所区分
            type: 'select',
            id: 'arrivalPlaceType',
            columnInfo: searchConditionInfo.arrival_place_type,
            options: getListOptions(this.MASTER_CODE.PLACE_LEVEL),
            onChangeCallback: () => {},
          },
          {
            // 搬入場所
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.placeModalKbn = 2
              //this.openSubModal(this, 'place-modal')
              this.openSubModal(this, this.makeSubModalId)
            },
          },
          {
            // 分割パターン名
            type: 'text',
            id: 'dividePatternName',
            columnInfo: searchConditionInfo.divide_pattern_name,
          },
          {
            // デフォルト区分
            type: 'select',
            id: 'defaultType',
            columnInfo: searchConditionInfo.default_type,
            options: getListOptions(this.MASTER_CODE.DEFAULT_TYPE),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.SECTION_DIVISION_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.SECTION_DIVISION_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },
    /**
     * 複数選択しているか.
     * @return {Boolean}
     */
    multiSelected() {
      return this.selectedItem.length >= 1
    },

    /**
     * 場所参照モーダルID作成
     */
    makeSubModalId() {
      //開かれたdisplayModalsによりサブモーダルIDも変わるように修正
      let newId = 'place-modal'
      if (this.displayModals == 'two') {
        newId = 'place-modal-edit'
      } else if (this.displayModals == 'three') {
        newId = 'place-modal-three'
      } else if (this.displayModals == 'four') {
        newId = 'place-modal-four'
      }
      return newId
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    search,
    clear,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let defaultDeparturePlaceId = null
      let defaultArrivalPlaceId = null
      if (this.selectedSearchCondition.departurePlaceId != null) {
        defaultDeparturePlaceId = this.selectedSearchCondition.departurePlaceId
      }
      if (this.selectedSearchCondition.arrivalPlaceId != null) {
        defaultArrivalPlaceId = this.selectedSearchCondition.arrivalPlaceId
      }
      return {
        departurePlaceType: null,
        departurePlaceId: defaultDeparturePlaceId,
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceType: null,
        arrivalPlaceId: defaultArrivalPlaceId,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        dividePatternName: null,
        defaultType: null,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.SECTION_DIVISION_MODAL_LIST,
        domainName: 'result_fields',
      }
    },

    select() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('section-division-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('section-division-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('section-division-modal-three')
      }
      this.$emit('after-close-set', this.selectedItem)
    },
    unselect() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('section-division-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('section-division-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('section-division-modal-three')
      }
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
        let placeType = changePlaceKind(selectedItem.place_kind)
        this.searchCondition.departurePlaceType = placeType
      } else if (this.placeModalKbn == 2) {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
        let placeType = changePlaceKind(selectedItem.place_kind)
        this.searchCondition.arrivalPlaceType = placeType
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else if (this.placeModalKbn == 2) {
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
